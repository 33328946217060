import React from 'react';
import { Wrapper } from './styled';
import Contact from './contact';
import Links from './links';
import Qrcodes from './qrcodes';

export default function Footer() {
  return (
    <Wrapper>
      <div className="content">
        <Contact />
        <Links />
        <Qrcodes />
      </div>
      <div className="copyright">
        <p>COPYRIGHT © ALL RIGHTS RESERVED</p>
        <p>京公网安备 11010802030803号 京ICP备17062567号</p>
      </div>
    </Wrapper>
  );
}
