import React from 'react';
import GlobalStyle from './__Global.style';
import Header from './header';
import Footer from './footer';
import { Wrapper, Content } from './styled';

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Wrapper>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  </>
);

export default Layout;
